<template>
    <v-main>
        <div class="pt-6 pb-6 pr-3 pl-3">
            <v-container>
                <v-form>
                    <div>
                        <legend class="mb-3">
                            Faça o upload da planilha para solicitação de limites por lote
                        </legend>

                        <FilePicker
                            v-show="uploadExcelRequestStatus === RequestStatusEnum.IDLE"
                            ref="filePicker"
                            @onReset="resetted"
                            @onFileChange="fileChanged"
                            :acceptedFileTypes="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
                        />

                        <v-row class="mt-6 ml-0 mr-0 mb-6">
                            <v-btn
                                color="secondary"
                                outlined
                                depressed
                                block
                                class="mt-3"
                                target="_blank"
                                :href="fileModelPath"
                            >
                                Não possui a planilha modelo? Faça download dela aqui
                            </v-btn>
                        </v-row>
                    </div>

                    <Loader
                        align="center"
                        justify="center"
                        v-if="uploadExcelRequestStatus === RequestStatusEnum.START"
                    />

                    <section
                        class="section-response"
                        v-if="uploadExcelRequestStatus.type === RequestStatusEnum.ERROR"
                    >
                        <textarea
                            class="custom-textarea mt-6"
                            :value="formatError(uploadExcelRequestStatus.message)"
                        >
                        </textarea>
                    </section>

                    <footer align="right" class="mt-6">
                        <v-btn
                            v-show="uploadExcelRequestStatus.type === RequestStatusEnum.ERROR"
                            @click="reset"
                            color="secondary"
                            depressed
                        >
                            {{ $tc('defaults.form.redo') }}
                        </v-btn>

                        <v-btn
                            v-show="uploadExcelRequestStatus.type !== RequestStatusEnum.ERROR"
                            :disabled="
                                !selectedFile ||
                                uploadExcelRequestStatus === RequestStatusEnum.START
                            "
                            @click="uploadFile"
                            color="secondary"
                            depressed
                        >
                            {{ $tc('defaults.form.confirm') }}
                        </v-btn>
                    </footer>
                </v-form>
            </v-container>
        </div>
    </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import FilePicker from '@/components/base/FilePicker/FilePicker';
import Loader from '@/components/base/Loader';
import { notification } from '@/helpers/notification';
import { RequestStatusEnum } from '@/helpers/consts';

export default {
    components: {
        FilePicker,
        Loader,
    },
    data: () => ({
        uploadError: null,
        selectedFile: null,
        RequestStatusEnum,
    }),
    watch: {
        uploadExcelRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                notification(
                    this.$dialog.notify.success,
                    'Solicitação em lote efetuada com sucesso'
                );
                this.$refs.filePicker.reset();
                this.closeModal();
                this.reset();
                return;
            }
        },
    },
    async mounted() {},
    methods: {
        ...mapActions('credito', {
            uploadExcelRequest: 'uploadExcelRequest',
            dismissExcelRequestStatus: 'dismissExcelRequestStatus',
        }),

        reset() {
            this.dismissExcelRequestStatus();
            this.selectedFile = null;
        },
        uploadFile() {
            const formData = new FormData();
            formData.append('file', this.selectedFile, this.selectedFile.name);
            this.uploadExcelRequest({ idProduct: this.selectedProduct.id, formData });
        },
        resetted() {
            this.selectedFile = null;
        },
        fileChanged(file) {
            this.selectedFile = file;
        },
        formatError(value) {
            return value.replace(/\|/gi, '\n');
        },
    },
    computed: {
        ...mapGetters('credito', {
            uploadExcelRequestStatus: 'uploadExcelRequestStatus',
            limitsPerBatchRequestStatus: 'limitsPerBatchRequestStatus',
            selectedProduct: 'selectedProduct',
        }),
        fileModelPath() {
            const productName = this.selectedProduct.register.name.toLowerCase();
            return `https://farminvestimentos.com.br/wp-content/themes/farm/modelos/${productName}_solicitacoes.xlsx`;
        },
    },
};
</script>
<style lang="scss" scoped>
.v-form {
    max-width: 800px;
    margin: 0 auto;
}
</style>

